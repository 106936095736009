import React, { useEffect, useRef, useState, DependencyList } from "react";
import { Button } from "../components/Button";
import api from "../api/api";
import { FiPrinter, FiUpload, FiDownload } from "react-icons/fi";
import { Modal } from "../components/Modal";
import "moment/locale/it";
import { HiCheck, HiMagnifyingGlass } from "react-icons/hi2";
import { BrowserMultiFormatReader, BarcodeFormat } from "@zxing/library";

import "react-image-crop/dist/ReactCrop.css";
import { PixelCrop, ReactCrop } from "react-image-crop";
import { convertDateMrz } from "../utils/convertDate";
const TO_RADIANS = Math.PI / 180;
export const DocumentoModal = ({
  tipo,
  id_scheda,
  id_sel,
  readCf,
  associate,
}) => {
  const [open, setOpen] = useState(false);
  const [fronte, setFronte] = useState(null);
  const [retro, setRetro] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [src, setSrc] = useState(false);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState();
  const [output, setOutput] = useState();
  const imageRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState();
  const hiddenAnchorRef = useRef(null);

  const blobUrlRef = useRef("");
  const [mrz, setMrz] = useState(false);
  const [cf, setCf] = useState();
  const handleModalOpen = async (e) => {
    e.preventDefault();

    const res_files = await api.get("/allegati/documento", {
      params: {
        scheda: id_scheda,
        id_sel: id_sel,
        tipo: tipo,
      },
    });
    setFronte(res_files.data.fronte);
    setRetro(res_files.data.retro);

    setOpen(true);
  };

  const handleFronteSel = (e) => {
    const file = e.target.files[0];
    file.change = true;
    setFronte(file);
  };

  const handleRetroSel = (e) => {
    const file = e.target.files[0];
    file.change = true;
    setRetro(file);
  };

  const handleOpenCrop = () => {
    setOpenCrop(true);
    setSrc(URL.createObjectURL(retro));
  };

  const handleFileUpload = async () => {
    if (!fronte && !retro) {
      alert("ATTENZIONE, nessun file selezionato");
    }
    if (fronte && fronte.change) {
      const formDataFronte = new FormData();
      formDataFronte.append("file", fronte);
      formDataFronte.append("scheda", id_scheda);
      formDataFronte.append("id_sel", id_sel);
      formDataFronte.append(
        "note",
        "fronte documento " + (tipo == "defunto" ? "defunto" : "richidente")
      );
      formDataFronte.append("tipo", tipo);
      formDataFronte.append("tipo_doc", 1);
      try {
        let resp_file = await api.post("/allegati/documento", formDataFronte, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });
      } catch (error) {
        console.log(error);
      }
    }

    if (retro && retro.change) {
      const formDataRetro = new FormData();
      formDataRetro.append("file", retro);
      formDataRetro.append("scheda", id_scheda);
      formDataRetro.append("id_sel", id_sel);
      formDataRetro.append(
        "note",
        "retro documento " + (tipo == "defunto" ? "defunto" : "richidente")
      );
      formDataRetro.append("tipo", tipo);
      formDataRetro.append("tipo_doc", 2);
      try {
        let resp_file = await api.post("/allegati/documento", formDataRetro, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
    alert("Documento caricato con successo!");
  };

  const handleScanData = async (blob) => {
    const reader = new FileReader();

    reader.onload = async () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const codeReader = new BrowserMultiFormatReader();

        // Configura il lettore per cercare solo il formato PDF417
        const hints = new Map();
        hints.set(BarcodeFormat.PDF_417, true);

        codeReader
          .decodeFromImageElement(image)
          .then((result) => {
            console.log(result.text);
            readCf(result.text);
            setCf(result.text);
          })
          .catch((err) => {
            console.error("Errore nella scansione del barcode: ", err);
          });
      };
    };
    reader.readAsDataURL(retro);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imageRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imageRef.current,
          previewCanvasRef.current,
          completedCrop
        );
        if (!mrz) {
          const image = imageRef.current;
          const scaleX = image.naturalWidth / image.width;
          const scaleY = image.naturalHeight / image.height;
          const previewCanvas = previewCanvasRef.current;
          const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY
          );
          const ctx = offscreen.getContext("2d");
          ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height
          );
          if (!ctx) {
            throw new Error("No 2d context");
          }
          const blob = await offscreen.convertToBlob({
            type: "image/png",
          });

          //check mrz
          if (!cf) {
            handleScanData(blob);
          }

          const formData = new FormData();
          formData.append("file", blob);

          try {
            let resp_file = await api.post("/allegati/scan", formData, {
              headers: {
                "content-type": "multipart/form-data",
              },
            });
            setMrz(resp_file.data);
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    100,
    [completedCrop]
  );

  const associateData = () => {
    let nome = mrz?.fields.firstName;
    let cognome = mrz?.fields.lastName;
    let dataNascita = convertDateMrz(mrz?.fields.birthDate);
    let numero_doc = mrz?.fields.documentNumber;
    let scadenza_doc = convertDateMrz(mrz?.fields.expirationDate);

    associate(nome, cognome, dataNascita, numero_doc, scadenza_doc);
  };

  return (
    <>
      <div>
        {" "}
        <Button
          icon={<FiPrinter />}
          label="Documento"
          onClick={(e) => handleModalOpen(e)}
        />
      </div>
      <Modal open={openCrop} onClose={() => setOpenCrop(false)}>
        <div className="grid grid-cols-2 gap-2">
          <div>
            {!!src && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                // minWidth={400}
                minHeight={100}
                // circularCrop
              >
                <img alt="Crop me" src={src} ref={imageRef} />
              </ReactCrop>
            )}
          </div>
          <div>
            {!!completedCrop && (
              <>
                <div>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: "1px solid black",
                      objectFit: "contain",
                      width: completedCrop.width,
                      height: completedCrop.height,
                    }}
                  />
                </div>
                <div>
                  <div style={{ fontSize: 12, color: "#666" }}>
                    {mrz ? (
                      <>
                        Dati Rilevati!
                        <br />
                        CF: {cf}
                        <br />
                        Nome: {mrz?.fields.firstName}
                        <br />
                        Cognome: {mrz?.fields.lastName}
                        <br />
                        DataDiNascita: {convertDateMrz(mrz?.fields.birthDate)}
                        <br />
                        Documento n°: {mrz?.fields.documentNumber}
                        <br />
                        Scadenza documento:{" "}
                        {convertDateMrz(mrz?.fields.expirationDate)}
                      </>
                    ) : (
                      <>
                        Cerca di inquadra l'mrz nella maniera più completa
                        possibile
                      </>
                    )}
                  </div>
                  {mrz && (
                    <>
                      {" "}
                      <Button
                        label="Associa"
                        onClick={associateData}
                        icon={<HiCheck />}
                      />
                    </>
                  )}
                  <a
                    href="#hidden"
                    ref={hiddenAnchorRef}
                    download
                    style={{
                      position: "absolute",
                      top: "-200vh",
                      visibility: "hidden",
                    }}
                  >
                    Hidden download
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
        <div>{output && <img src={output} />}</div>
      </Modal>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div className="flex flex-row">
          <div className="flex flex-col gap-4 pr-2">
            <div className="flex flex-row">
              <Button
                label="Fronte"

                // onClick={handleFileUpload}
              />
            </div>
            {fronte?.id ? (
              <div className="flex flex-row">
                <Button
                  label={fronte.nome_file}
                  fullWidth={true}
                  icon={<FiDownload />}
                  // onClick={handleFileUpload}
                />
              </div>
            ) : fronte?.change ? (
              <div> Il file che verrà caricato è il seguente: </div>
            ) : (
              <div> Nessun file ancora caricato</div>
            )}
            <div>
              <input
                id="upload_button"
                type="file"
                onChange={(e) => handleFronteSel(e)}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4 pl-2 border-l-4 w-full">
            <div className="flex flex-row w-full">
              <Button
                label="Retro"

                // onClick={handleFileUpload}
              />
            </div>
            {retro?.id ? (
              <div className="flex flex-row w-full">
                <Button
                  label={retro.nome_file}
                  icon={<FiDownload />}
                  fullWidth={true}
                  // onClick={handleFileUpload}
                />
              </div>
            ) : retro?.change ? (
              <div> Il file che verrà caricato è il seguente: </div>
            ) : (
              <div> Nessun file ancora caricato</div>
            )}
            <div>
              <input
                id="upload_button"
                type="file"
                onChange={(e) => handleRetroSel(e)}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row-reverse">
          <Button
            label="Carica"
            icon={<FiUpload />}
            onClick={handleFileUpload}
          />
          {retro && (
            <Button
              label="Scan"
              icon={<HiMagnifyingGlass />}
              onClick={() => handleOpenCrop()}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

async function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

export function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}

/*

 async function onDownloadCropClick() {
    const image = imageRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    if(!mrz){
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });
    //check mrz
    const formData = new FormData();
    formData.append("file", blob);

    try {
      let resp_file = await api.post("/allegati/scan", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      setMrz(resp_file.data);
    } catch (error) {
      console.log(error);
    }

}
    /*  if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(blob);

    if (hiddenAnchorRef.current) {
      hiddenAnchorRef.current.href = blobUrlRef.current;
      hiddenAnchorRef.current.click();
    }
    }
*/
