export const convertDate = (dataString) => {
  // Crea un oggetto data dalla stringa
  var data = new Date(dataString);

  // Aggiungi l'offset di fuso orario per Roma (+1 ora rispetto all'UTC)
  data.setHours(data.getHours() + 1);

  // Ottieni i componenti della data
  var giorno = data.getDate();
  var mese = data.getMonth() + 1; // Gennaio è 0!
  var anno = data.getFullYear();

  // Formatta i componenti della data con zero iniziali se necessario
  if (giorno < 10) {
    giorno = "0" + giorno;
  }
  if (mese < 10) {
    mese = "0" + mese;
  }

  // Ritorna la data nel formato DD/MM/YYYY
  return giorno + "/" + mese + "/" + anno;
};

export const convertDateMrz = (dataString) => {
  const splitted = dataString.match(/.{1,2}/g);
  return `${splitted[2]}/${splitted[1]}/${splitted[0]}`;
};
